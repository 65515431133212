import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconRelocation.module.scss';

const IconRelocation = ({ shouldStart = false, id = '' }) => {
    const RelocationAnimate = () => {
        anime({
            targets: `.${id}relocationLine`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 800,
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? RelocationAnimate() : null;
    });

    return (
        <div className={styles['IconRelocation']}>
            <svg
                viewBox="0 0 51 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.281 21a1 1 0 0 0 2 0h-2zm18.832 15.842a1 1 0 0 0 0 2v-2zm11.571-25.58.57-.822-.57.822zM17.281 21v-8.136h-2V21h2zm.404-8.939L30.328 2.66l-1.193-1.605-12.644 9.402 1.194 1.604zm13.81-9.42 13.62 9.443 1.139-1.644L32.634.997l-1.14 1.643zm14.05 10.265v22.936h2V12.906h-2zm-1 23.936H34.112v2h10.431v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm-.43-23.758a1 1 0 0 1 .43.822h2a3 3 0 0 0-1.291-2.466l-1.14 1.644zM30.327 2.66a1 1 0 0 1 1.167-.02l1.14-1.643a3 3 0 0 0-3.5.058l1.193 1.605zM17.281 12.864a1 1 0 0 1 .404-.803l-1.194-1.604a3 3 0 0 0-1.21 2.407h2z"
                    fill="currentColor"
                />
                <path
                    className={id + 'relocationLine'}
                    d="m16.988 34.41 12.405-4.604V43.8l-12.405 4.65V34.41z"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className={id + 'relocationLine'}
                    d="M17.018 34.41 4.613 29.805V43.8l12.405 4.65V34.41z"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className={id + 'relocationLine'}
                    d="m17.018 34.41 12.376-4.604-12.376-4.492-12.405 4.492 12.405 4.603z"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

IconRelocation.propTypes = {};

export default IconRelocation;
